import React, { useState, useEffect } from 'react';
//import { Toggle } from './Toggle';
import menu from '../menu.json';
import '../App.css' 

export const MorningBoostersSweet = () => {
    const [morningBoostersSweet, setMorningBoostersSweet] = useState([]);

    useEffect(() => {
        setMorningBoostersSweet(menu.menu['Morning-Boosters-sweet']);
    }, []);

    return (
        <div className='morning-boosters'>
            {morningBoostersSweet && morningBoostersSweet.map((item, index) => (
                <div className='food'  key={index}>
                    <h3>{item.title+" -"+item.price} </h3>

                    <div>
                    <img src={item.image} loading='lazy' alt= {item.title} />
                    </div>

                    <p>{item.descriptionEs}</p>
                    <i>{item.descriptionEn}</i>

                </div>
            ))}

            <div className='morning-boosters-extras'>

                <h3>AÑADE TOPPINGS</h3>
                <h3>ADD TOPPINGS</h3>
                <br />
                <p>Dulce de leche&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.50€</p>
                <br />
                <p>Nutella vegana&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.00€</p>
                <i>Vegan Nutella&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.00€</i>
                <br />
                <br />
                <p>Mantequilla de cacahuete&nbsp;&nbsp;&nbsp;&nbsp;2.00€</p>
                <i>Peanut butter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.00€</i>
                <br />
                <br />
                <p>Mermelada de fresa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.00€</p>
                <i>Strawberry jam&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.00€</i>
                <br /><br />

            </div>
        </div>
    );
};

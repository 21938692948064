import React, { useState, useEffect } from 'react';
//import { Toggle } from './Toggle';
import menu from '../menu.json';
import '../App.css' 

export const MorningBoostersSalt = () => {
    const [morningBoostersSalt, setMorningBoostersSalt] = useState([]);

    useEffect(() => {
        setMorningBoostersSalt(menu.menu['Morning-Boosters-salt']);
    }, []);

    return (
        <div className='morning-boosters'>
            {morningBoostersSalt && morningBoostersSalt.map((item, index) => (
                <div className='food' key={index}>
                    <h3>{item.title+" -"+item.price} </h3>

                    <div>
                    <img src={item.image} loading='lazy' alt= {item.title} />
                    </div>

                    <p>{item.descriptionEs}</p>
                    <i>{item.descriptionEn}</i>

                </div>
            ))}

            <div className='morning-boosters-extras'>

                <h3>EXTRAS</h3>
                <br />
                <p>Avocado · <i>Avocado&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.50€</i></p>
                <br />
                <p>Huevo pochado · <i>Poched egg &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.80€</i></p>
                <br />
                <p>Salmón ahumado · <i>Smoked salmon&nbsp;&nbsp;&nbsp;&nbsp;4.50€</i></p>
                <br />
                <p>Salmón marinado · <i>Poke salmon&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.50€</i></p>
                <br />
                <p>Salmón salteado · <i>Sautéed salmon&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.50€</i></p>
                <br />
                
            </div>
        </div>
    );
};

import React from 'react'
import { useState } from 'react';


export const Toggle = ({title, children}) => {

const [modal, setModal] = useState(false);
 
const toggleModal =  () => { 
    setModal(!modal);
}

return (
    <div>
      <h1 className='btn-modal' onClick={toggleModal}>
        {title}
      </h1>

      {modal && (
        <div className='modal'>
          <div className='modal-content'>
            {children}
            <img 
              className='close-button'
              src={`${process.env.PUBLIC_URL}/close-button.png`} 
              alt="Cerrar" 
              onClick={toggleModal}
            />
          </div>
        </div>
      )}
    </div>
  );
}
